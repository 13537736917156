  
  /**
   * @example
   * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
   */
  export const pageview = (url: string, trackingId: string) => {
    if (!window.gtag) {
      console.warn(
        "window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.",
      );
      return;
    }
    window.gtag("config", trackingId, {
      page_path: url,
    });
  };
  

  export const commerceEvent = (props: any) => {
    if (!window.dataLayer) {
      console.warn(
        "window.datalayer is not defined. This could mean your google analytics script has not loaded on the page yet.",
      );
      return;
    }

    const { eventName, currency, value, itemName, transactionId } = props
    window.dataLayer.push({ecommerce: null})
    window.dataLayer.push({
      transaction_id: transactionId,
      event: eventName,
      currency,
      value,
      ecommerce: {
        items: [{
          item_name: itemName,
        }]
      }
    })
  }

  /**
   * @example
   * https://developers.google.com/analytics/devguides/collection/gtagjs/events
   */
  export const event = ({
    action,
    category,
    label,
    value,
  }: Record<string, string>) => {
    if (!window.gtag) {
      console.warn(
        "window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.",
      );
      return;
    }
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };